import logoBlueRse from './assests/images/logoBlueRse.png';
import logowhite from './assests/images/logo.png';
import logoPngImng from './assests/images/loginPageImg.jpg';
import nodataFound from './assests/images/nodataFound.png'
import businessimg from './assests/images/businessImage.jpg'
import contactusimg from './assests/images/contactus.png'
import aboutusimg from './assests/images/aboutus.png'


//blue logo
export const  logoBlue = logoBlueRse;
//white logo
export const  logo = logowhite;

//cotnact logo
export const  contactusIMG = contactusimg;

//about logo
export const  aboutusIMG = aboutusimg;




//logo png
export const  logoPngImg = logoPngImng;
export const businessImg = businessimg



export const noDataFoundimg = nodataFound


//base url
export const  baseURL = process.env.REACT_APP_BASE_URL

//image path
export const  flyerImagePath = process.env.REACT_APP_FLYER_IMAGE_PATH

//busineess Image Path 
export const  businessImagePath = process.env.REACT_APP_BUSINESS_IMAGE_PATH


//slider image path

export const sliderImagePath=process.env.REACT_APP_SLIDER_IMAGE_PATH